.eachPageHeaderCover{
    display: flex;
font-size: 13px;
fill: #7F7F7F;
height: 20px;
align-items: center;
width: 200px;
justify-content: space-between;
color: #7F7F7F;
}

.eachPageHeaderCover small:last-child{
    color: #ff0066;

}

.eachPageHeader{
color: #7F7F7F;

}