.allLayoutCover{
    width: 100%;
    position: relative;
}
.premium{
    width: 100%;
    height: 35px;
    background: #E9EAF9;
    display: flex;
    align-items: center;
}

.premium small{
    margin-left: auto;
    margin-right: 80px;
    color: #624F63;
    font-size: 13px;
}

.layoutCover{
    background-color: #f9f9f9;
    width: 100%;
    height: 100vh;
}

.splitSidebarCover{
    display: flex;
}

.mainSideBarSection{
    width: 290px;  
    border: 2px solid #f5f5f5;
    box-shadow: 0px 0px 13px #f5f5f5;
    /* height: 400px; */
    background-color: #fff;
    transition: .200s;
}


body[data-side-navigation-mode="open"] .mainSideBarSection{
    transform: translateX(0) !important;
}


.bodySectionSection{
    /* padding: 0px 2em; */
    width: 100%;
    box-sizing: border-box;
}

@media (max-width: 999px) {
    .mainSideBarSection {
    width: 230px;  
    position: absolute;
    z-index: 1;
    transform: translateX(-330px) !important;

    }
  }
  