.flexSection {
  background-color: #f9f9f9;
  width: 100%;
  display: flex;
}
.gridDashboardCard {
  display: grid;
  width: 100%;
  grid-gap: 20px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin-top: 30px;
}
.subNavCover {
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.PageHeaderCover {
  display: flex;
  justify-content: space-between;
  height: 40px;
  align-items: center;
}
.sideEachPageHeader {
  display: flex;
  width: max-content;
  height: 100%;
  align-items: center;
}
.eachPageHeader {
  color: #7f7f7f;
}
.secondForm {
  width: 230px;
  margin-right: 20px;
  height: 40px;
  border-radius: 6px;
  box-shadow: 0px 0px 3px #6c757d;
  overflow: hidden;
  display: flex;
  box-sizing: border-box;
}
.searchFormSection {
  background-color: #fff;
  width: 100%;
  height: 100%;
  border: none;
  color: #6c757d;
  padding-left: 12px;
  outline: none;
}
.buttonFormSection {
  border-radius: 5px;
  background-color: #ff0066;
  outline: none;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-width: 40px;
}

.orderDetailsCover {
  width: 100%;
  margin-top: 20px;
  background-color: #fff;
  padding: 1.3em;
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
}

.orderStatus {
  display: flex;
  color: #7f7f7f;
  font-size: 14px;
  font-weight: bold;
}

.trueVisibilityCover {
  width: max-content;
  padding: 0.4em 1.5em;
  background-color: #ff0066;
  height: max-content;
  border: none;
  outline: none;
  border-radius: 10px;
  color: #fff;
  font-size: 10px;
}
.companyDetails {
  text-align: right;
  display: flex;
  flex-direction: column;
  color: #7f7f7f;
}
.cardCover {
  width: 100%;
  position: relative;
  padding: 1em;
  box-sizing: border-box;
  padding-bottom: 2em;
  overflow: hidden;
}
.mappedStoresCover {
  width: 100%;
  overflow: hidden;
  margin-top: 20px;
}
.cardListingCover {
  width: 100%;
}

.cardHeader {
  border-color: #dee2e6;
  box-shadow: 0px 0px 5px #6c757d;
  color: #6c757d;
  filter: drop-shadow(0px 0px 2px #6c757d);
  display: grid;
  width: 100%;
  overflow: hidden;
  padding: 15px 10px;
  background: #e9eaf9 !important;
  box-sizing: border-box;
  font-size: 14px;
  align-self: center;
  text-align: center;
  grid-template-columns: 1fr 0.3fr 0.2fr 0.3fr 0.4fr;
}

.mappedOrders {
  background-color: #fff;
  height: 90px;
  border: 2px solid #f5f5f5;
  box-shadow: 0 px 0px 23px #f5f5f5;
  color: #6c757d;
  display: grid;
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
  font-size: 14px;
  grid-template-columns: 1fr 0.3fr 0.2fr 0.3fr 0.4fr;
}
.mappedOrders:nth-child(odd) {
  background-color: #e9eaf998;
}
.mappedOrders div {
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 2px solid #f5f5f5;
  height: 100%;
}
.myOrderItemCover {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  margin-right: auto;
  text-transform: capitalize;
  padding-left: 20px;
}
.myOrderItemImage {
  width: 45px;
  margin-right: 12px;
  height: 45px;
  border-radius: 30%;
}
.removeBtn {
  width: max-content;
  height: max-content;
  padding: 3px 8px;
  font-size: 11px;
  background-color: #000033;
  border-radius: 8px;
  cursor: pointer;
  border: none;
  color: #fff;
  outline: none;
}
.showingCover {
  width: 140px;
  height: 34px;
  font-size: 13px;
  margin-top: 10px;
  font-weight: 300;
  color: #7f7f7f;
  display: flex;
  align-items: center;
  border: 1px solid #7f7f7f;
  border-radius: 4px;
  padding-left: 10px;
  box-sizing: border-box;
}
.paginationClass {
  display: flex;
  width: max-content;
  margin-left: auto;
}
.paginationClass li {
  list-style: none;
  color: #521c54;
  margin: 0px 8px;
  width: 25px;
  cursor: pointer;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.paginationClass li[class='selected'] {
  background-color: #ff0066;
  color: #fff;
  padding-right: 1px;
}
.totalCover {
  display: flex;
  justify-content: flex-end;
  color: #7f7f7f;
  height: 40px;
  margin-bottom: 20px;
  align-items: center;
}

@media (max-width: 1246px) {
  .gridDashboardCard {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
}
@media (max-width: 680px) {
  .cardCover {
    overflow-x: scroll;
  }
  .mappedStoresCover {
    width: 650px;
  }
}
