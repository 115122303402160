.flexSection {
  width: 100%;
  display: flex;
}
.combinedHeader {
  display: flex;
  width: 100%;
  height: max-content;
  align-items: center;
}

.gridTwoFiles {
  display: grid;
  grid-template-columns: 0.4fr 1fr;
  grid-gap: 20px;
}

.paddingCard {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.avatar {
  height: 52px;
  width: 52px;
  padding: 3px;
  margin: auto;
  border: 1px solid #dee2e6;
  border-radius: 50%;
}

.profileName {
  color: #343a40;
  text-transform: capitalize;
  text-align: center;
  margin: 0.75rem 0px 0px 0px !important;
}

.textLeft {
  color: #6c757d;
  font-size: 13px;
  margin-top: 29px;
}

.ml_2 {
  margin-left: 4px;
}

.flexTwo {
  display: flex;
  /* justify-content: space-between; */
}

.twoRoutes {
  border-radius: 0.25rem;
  background-color: #f7f7f7;
  margin: 0 5px;
  color: #6c757d;
  font-weight: 600;
  padding: 0.5rem 1rem;
  width: 100%;
  cursor: pointer;
  font-size: 14px;
  text-align: center;
}

.personalInfoCover {
  /* padding: 0.75rem !important; */
  margin-bottom: 1.5rem !important;
  margin-top: 1.5rem !important;
  /* background-color: #f1f5f7 !important; */
  /* margin: 10px 0; */
  font-weight: bold;
  font-size: 13px;
  color: #7f7f7f;
}

.gridTwo {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 1em;
}

.headerSection {
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  /* border-bottom: 1px solid; */
  padding: 1rem 1rem;
  /* border-bottom: 1px solid #dee2e6; */
  border-top-left-radius: 0.2rem;
  border-top-right-radius: 0.2rem;
  color: #343a40;
  fill: #343a40;
}

.subHeaderSection {
  flex-direction: column;
  box-sizing: border-box;
  display: flex;
  padding: 1rem 1rem;
  color: #343a40;
}
.bottomBorder {
  display: flex;
  /* border-bottom: 1px solid; */
  padding: 1rem 1rem;
  /* border-top: 1px solid #dee2e6; */
  border-bottom-left-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.pageHeader {
  margin: 20px 0px 15px 0px;
  color: #7f7f7f;
  font-weight: bold;
  font-size: 14px;
}

.amountCover {
  width: max-content;
  height: max-content;
  position: relative;
  width: 100%;
}
.nairaCover {
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 50px;
  color: #6c757d;
  height: calc(1.5em + 0.9rem - 1px);
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid #ced4da;
}
.firstCardCover {
  width: 100%;
}

@media (max-width: 900px) {
  .pageHeader {
    display: none;
  }
  .gridTwoFiles {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }
  .firstCardCover {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }
}
.msgAlert {
  color: #806027;
  background-color: #fdf1db;
  position: relative;
  width: 75%;
  margin: 10px auto !important;
  box-sizing: border-box;
  margin-top: 40px !important;
  padding: 0.55rem 1.25rem;
  border: 1px solid #fdebcd;
  border-radius: 0.25rem;
}
.msgItself {
  color: #323a46 !important;
}
@media (max-width: 600px) {
  .firstCardCover {
    grid-template-columns: 1fr;
  }
}
