.navCoverMain {
  width: 100%;
  background-color: #fff;
  display: flex;
  height: 80px;
}

.miniNav {
  height: 100%;
  align-items: center;
  width: 90%;
  margin: auto;
  display: flex;
}

/* .miniNav ul { */
/* display: flex; */
/* } */

.miniNav ul li {
  list-style: none;
  font-weight: bold;
  font-size: 14px;
  color: #969696;
  margin-right: 26px;
}

.searchFormSection {
  height: 46px;
  width: 522px;
  max-width: 100%;
  border: 1px solid #f5f5f5;
  box-shadow: 0px 0px 3px #f5f5f5;
  border-radius: 8px;
  position: relative;
  margin-left: 10px;
  display: flex;
  /* overflow: hidden; */
}

.secondSearchFormSection {
  height: 46px;
  /* height: 76%; */
  width: 522px;
  max-width: 100%;
  background-color: #f9f9f9;
  border-radius: 8px;
  position: relative;
  margin-left: 10px;
  display: none;
  /* overflow: hidden; */
}

.searchInputSection {
  background-color: inherit;
  height: 100%;
  border: none;
  color: #969696;
  outline: none;
  padding-left: 1em;
  width: 120%;
  box-sizing: border-box;
}

.mainSearchSectionBTN {
  background-color: #ff0066;
  width: 50px;
  border: none;
  border-radius: 8px;
  outline: none;
  height: 100%;
  position: absolute;
  right: 0px;
  top: 0px;
}

.customSelectSection {
  background-color: #e9eaf9;
  width: 100%;
  height: 100%;
  padding: 1em;
  box-sizing: border-box;
  align-items: center;
  cursor: pointer;
  display: flex;
}

.eachCountryCover:hover {
  background-color: #e9eaf9;
}

.sectionImage {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 17px;
}

.strongCountryName {
  color: #444;
  font-size: 13px;
  font-weight: 500;
  margin-right: 17px;
}

.rightSideNavDesign {
  display: flex;
  margin-left: auto;
  height: 100%;
  align-items: center;
}

.profileRightSideNav {
  margin: 0px 20px;
  cursor: pointer;
}

.allCountryMap {
  position: absolute;
  width: 260px;
  height: 300px;
  background-color: #fff;
  border: 1px solid #f5f5f5;
  overflow-y: scroll;
  box-shadow: 0px 0px 3px #f5f5f5;
  z-index: 10;
  top: 60px;
  right: 0;
}

.eachCountryCover {
  padding: 1em;
  box-sizing: border-box;
  transition: 0.1s ease-in-out;
}

.Logo {
  display: block;
}
.Slogo {
  height: 30px;
  display: none;
}
.AiOutlineMenu {
  display: none;
  margin-right: 11px;
  cursor: pointer;
}
.ulFlex {
  display: flex;
}

.secondFormCover {
  display: none;
  width: 100%;
  padding: 0px 2em 20px 2em;
  box-sizing: border-box;
}

.mainNavDrop {
  position: absolute;
  will-change: transform;
  width: 170px;
  animation-fill-mode: both;
  border: none;
  font-size: 0.875rem;
  animation-name: DropDownSlide;
  box-shadow: 0 0 35px 0 rgb(154 161 171 / 15%);
  padding: 0.3rem;
  font-size: 0.875rem;
  color: #212529;
  text-align: left;
  list-style: none;
  /* top: 100%; */
  top: 0%;
  right: 70px;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 0.25rem;
  z-index: 101;
  transform: translate3d(18px, 70px, 0px);
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
.textOverflow {
  background-color: #fff;
  padding: 15px 20px 15px 20px;
  margin-bottom: 0;
  margin-top: 0;
  color: inherit;
  font-size: 0.75rem;
  white-space: nowrap;
  font-weight: bold;
  color: #343a40;
}

.dropdownDivider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.eachNavLinkSide {
  padding: 7px 20px;
  clear: both;
  font-weight: 400;
  color: #6c757d;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  display: block;
  width: 100%;
}
.Logout {
  fill: #f5f6f8;
  width: 27px;
  margin-left: 23px;
}

  
.AiOutlineMenu{
  display: none;
}

.topRightCounter{
  background-color: #ff0066;
  width: 12px;
  height: 12px;
  font-size: 8px;
  color: #fff;
  font-weight: bold;
  border-radius: 50%;
  right: -4px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -4px;
  position: absolute;
}
.confirmRemoval{
  color: 000033;
}

.removed{
  color: #7F7F7F;
  font-size: 14px;
  width: 300px;
}


@media (max-width: 1220px) {
  .searchFormSection {
    width: 400px;
  }
  .navCoverMain {
    height: 65px;
  }
}

@media (max-width: 1099px) {
  .miniNav {
    width: 94%;
  }
}

@media (max-width: 1039px) {
  .miniNav ul {
    padding-left: 20px;
  }
  .miniNav ul li {
    margin-right: 10px;
  }
  .profileRightSideNav {
    margin: 0px 10px;
  }
  .searchFormSection {
    width: 320px;
  }
}

@media (max-width: 889px) {
  .Logo {
    display: none;
  }
  .Slogo {
    display: block;
  }
  .navCoverMain {
    height: 55px;
  }
  .ulFlex {
    display: none;
  }
  .searchFormSection {
    width: 390px;
  }
}

@media (max-width: 759px) {
  .searchFormSection {
    width: 300px;
  }

  .searchInputSection {
    width: 70%;
  }
}

@media (max-width: 679px) {
  .profileRightSideNav {
    display: block;
  }
  .Slogo {
    margin-left: 10px;
  }
}

@media (max-width: 999px) {
  .AiOutlineMenu {
    display: block;
  }
}

@media (max-width: 569px) {
  .profileRightSideNav {
    display: block;
  }
  .ulFlex {
    display: none;
  }
  .Slogo {
    margin-left: 10px;
  }
  .navCoverMain {
    flex-direction: column;
    height: 140px;
  }
  .secondSearchFormSection {
    display: flex;
    /* margin: 0px 20px; */
    height: 46px;
  }
  .searchInputSection {
    width: 60%;
  }
  .searchFormSection {
    display: none;
  }
  .searchInputSection {
    padding-left: 0px;
  }
  .customSelectSection {
    width: 50%;
  }
  .secondFormCover {
    display: block;
  }
}
