.flexSection {
  background-color: #f9f9f9;
  width: 100%;
  display: flex;
}
.gridDashboardCard {
  display: grid;
  width: 100%;
  grid-gap: 20px;
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: 30px;
}

.PageHeaderCover {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  height: 40px;
  align-items: center;
}
.sideEachPageHeader {
  display: flex;
  width: max-content;
  height: 100%;
  align-items: center;
}
.eachPageHeader {
  color: #7f7f7f;
}
.secondForm {
  width: 230px;
  margin-right: 20px;
  height: 40px;
  border-radius: 6px;
  box-shadow: 0px 0px 3px #6c757d;
  overflow: hidden;
  display: flex;
  box-sizing: border-box;
}
.searchFormSection {
  background-color: #fff;
  width: 100%;
  height: 100%;
  border: none;
  color: #6c757d;
  padding-left: 12px;
  outline: none;
}
.buttonFormSection {
  border-radius: 5px;
  background-color: #ff0066;
  outline: none;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-width: 40px;
}
.cardCover {
  width: 100%;
  position: relative;
  padding: 1em;
  box-sizing: border-box;
  padding-bottom: 2em;
  overflow: hidden;
}
.mappedStoresCover {
  width: 100%;
  overflow: hidden;
  margin-top: 20px;
}
.cardListingCover {
  width: 100%;
}
.cardHeader {
  border-color: #dee2e6;
  box-shadow: 0px 0px 5px #6c757d;
  color: #6c757d;
  filter: drop-shadow(0px 0px 2px #6c757d);
  display: grid;
  width: 100%;
  overflow: hidden;
  padding: 15px 10px;
  background: #e9eaf9 !important;
  box-sizing: border-box;
  font-size: 14px;
  align-self: center;
  text-align: center;
  grid-template-columns: 1fr 1fr 0.6fr 0.6fr;
}
.mappedOrders {
  background-color: #fff;
  position: relative;
  height: 60px;
  margin-bottom: 2px;
  color: #6c757d;
  display: grid;
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
  font-size: 14px;
  grid-template-columns: 1fr 1fr 0.6fr 0.6fr;
}
.mappedOrders:nth-child(odd) {
  background-color: #e9eaf998;
}
.mappedOrders div {
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  border-left: 2px solid #f5f5f5;
  height: 100%;
}

.myOrderItemCover:first-child {
  box-sizing: border-box;
}
.strongSection {
  margin-right: 6px;
  display: none;
}
.trueVisibilityCover {
  width: max-content;
  padding: 0.4em 1.5em;
  background-color: #ff0066;
  border: none;
  outline: none;
  border-radius: 10px;
  color: #fff;
  font-size: 10px;
}

.firstForm {
  width: 100%;
  height: 40px;
  border-radius: 6px;
  /* border: 1px solid #6c757d; */
  box-shadow: 0px 0px 3px #6c757d;
  overflow: hidden;
  /* display: flex; */

  box-sizing: border-box;
  display: none;
}

.subNavCover {
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}
.paginationClass {
  display: flex;
  width: max-content;
  margin-left: auto;
}
.paginationClass li {
  list-style: none;
  color: #521c54;
  margin: 0px 8px;
  cursor: pointer;
  width: 25px;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.paginationClass li[class='selected'] {
  background-color: #ff0066;
  color: #fff;
  padding-right: 1px;
}
.showingCover {
  width: 140px;
  height: 34px;
  font-size: 13px;
  margin-top: 10px;
  font-weight: 300;
  color: #7f7f7f;
  display: flex;
  align-items: center;
  border: 1px solid #7f7f7f;
  border-radius: 4px;
  padding-left: 10px;
  box-sizing: border-box;
}
.confirmRemoval {
  color: #000033 !important;
  margin-top: 0px;
  font-size: 16px;
}
.cover {
  width: 100%;
}
.updateDetailsCover {
  width: 100%;
  height: 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #e9eaf9;
  font-size: 14px;
  color: #7f7f7f;
}
.gridTwo {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}
.amountCover {
  width: max-content;
  height: max-content;
  position: relative;
  width: 100%;
}
.nairaCover {
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 50px;
  color: #6c757d;
  height: calc(1.5em + 0.9rem - 1px);
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid #ced4da;
}
.flexRight {
  display: flex;
  justify-content: flex-end;
}
.textSection{
  color: #7f7f7f;
  font-size: 13px;
}


@media (max-width: 800px) {
  .gridDashboardCard {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
}

@media (max-width: 680px) {
  .firstForm {
    display: flex;
    margin-bottom: 20px;
  }
  .secondForm {
    display: none;
  }
}

@media (max-width: 580px) {
  .mappedOrders {
    grid-template-columns: 1fr;
    grid-gap: 8px;
    /* height: 80px !important; */
    padding-bottom: 14px !important;
    position: relative;
    height: max-content;
    padding: 13px 0px;
    box-sizing: border-box;
  }
  .strongSection {
    box-sizing: border-box;
    display: block;
    height: max-content;
    border: none;
    padding-left: 10px;
  }
  .cardHeader {
    display: none;
  }
  .myOrderItemCover {
    place-items: left !important;
    margin-right: auto;
    box-sizing: border-box;
  }
  /* .myOrderItemCover:nth-child(1) {
    position: absolute;
    bottom: 15px;
    left: 14px;
    height: max-content;
    border: none;
  }
  .myOrderItemCover:nth-child(3) {
    position: absolute;
    top: 10px;
    right: 10px;
    height: max-content;
    border: none;
  } */
  .myOrderItemCover:nth-child(4) {
    position: absolute;
    bottom: 10px;
    font-weight: bold;
    right: 10px;
    height: max-content;
    border: none;
  }
  .flexRight {
    display: flex;
    justify-content: center;
  }
}
