.flexSection {
  width: 100%;
  display: flex;
}
.myOrdersCover {
  width: 100%;
}

.mappedStoresCover {
  width: 100%;
  overflow: hidden;
  margin-top: 20px;
}

.cardListingCover {
  width: 100%;
}

.cardHeader {
  border-color: #dee2e6;
  box-shadow: 0px 0px 5px #6c757d;
  color: #6c757d;
  filter: drop-shadow(0px 0px 2px #efefef);
  display: grid;
  width: 100%;
  overflow: hidden;
  padding: 15px 10px;
  background: #e9eaf9 !important;
  box-sizing: border-box;
  font-size: 14px;
  align-self: center;
  text-align: center;
  grid-template-columns: 0.4fr 1fr 0.3fr 0.3fr 0.3fr;
}

.mappedOrders {
  background-color: #fff;
  height: 60px;
  margin-bottom: 2px;
  color: #6c757d;
  display: grid;
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
  font-size: 14px;
  grid-template-columns: 0.4fr 1fr 0.3fr 0.3fr 0.3fr;
}
.mappedOrders:nth-child(odd) {
  background-color: #e9eaf998;
}
.mappedOrders div {
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 2px solid #f5f5f5;
  height: 100%;
}
.myOrderItemCover {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  margin-right: auto;
  text-transform: capitalize;
  padding-left: 20px;
}

.myOrderItemImage {
  width: 45px;
  margin-right: 12px;
  height: 45px;
  border-radius: 30%;
}

.view {
  color: #ff0066;
  background-color: inherit;
  border: none;
  outline: none;
  text-decoration: underline;
}
.cardCover {
  width: 100%;
  position: relative;
  padding: 1em;
  box-sizing: border-box;
  padding-bottom: 2em;
  overflow: hidden;
}

.totalCheckoutSection {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding-right: 70px;
  padding-top: 20px;
  overflow: hidden;
  box-sizing: border-box;
}
.pending {
  width: max-content;
  padding: 0.4em 1em;
  border-radius: 12px;
  font-size: 10px;
  background-color: #ff6251;
  color: #fff;
}

.completed {
  width: max-content;
  padding: 0.4em 1em;
  border-radius: 12px;
  font-size: 10px;
  box-sizing: border-box;
  background-color: #ff0066;
  color: #fff;
}

.dateCover {
  justify-content: center;
  display: block;
}

.subNavCover {
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.firstForm {
  width: 100%;
  height: 40px;
  border-radius: 6px;
  /* border: 1px solid #6c757d; */
  box-shadow: 0px 0px 3px #6c757d;
  overflow: hidden;
  /* display: flex; */

  box-sizing: border-box;
  display: none;
}
.secondForm {
  width: 230px;
  margin-right: 20px;
  height: 40px;
  border-radius: 6px;
  /* border: 1px solid #6c757d; */
  box-shadow: 0px 0px 3px #6c757d;
  overflow: hidden;
  display: flex;

  box-sizing: border-box;
}
.buttonFormSection {
  border-radius: 5px;
  background-color: #ff0066;
  outline: none;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-width: 40px;
}
.searchFormSection {
  background-color: #fff;
  width: 100%;
  height: 100%;
  border: none;
  color: #6c757d;
  padding-left: 12px;
  outline: none;
}

.PageHeaderCover {
  display: flex;
  justify-content: space-between;
  height: 40px;
  align-items: center;
}
.eachPageHeader {
  /* margin-top: 8px; */
  color: #7f7f7f;
}
.sideEachPageHeader {
  display: flex;
  width: 450px;
  height: 100%;
  align-items: center;
}

.strongSection {
  margin-right: 6px;
  display: none;
}
.confirmRemoval {
  color: #000033 !important;
  margin-top: 0px;
  font-size: 16px;
}
.wrapperClassName {
  box-sizing: border-box;
  border: 1px solid #aaa;
  /* height: 500px; */
  /* height: 300px; */
  box-sizing: border-box;
  color: #7f7f7f !important;
  margin-bottom: 30px;
}
.editorClassName {
  color: #7f7f7f !important;
  height: 300px;
  box-sizing: border-box;
  /* overflow: hidden; */
}
.toolbarClassName {
  color: #7f7f7f !important;
  border-bottom: 1px solid #aaa;
}
.flexRight {
  display: flex;
  justify-content: flex-end;
}

.paginationClass {
  display: flex;
  width: max-content;
  margin-left: auto;
}
.paginationClass li {
  list-style: none;
  color: #521c54;
  margin: 0px 8px;
  cursor: pointer;
  /* padding: 8px; */
  width: 25px;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.paginationClass li[class='selected'] {
  background-color: #ff0066;
  color: #fff;
  padding-right: 1px;
}
.showingCover {
  width: 140px;
  height: 34px;
  font-size: 13px;
  margin-top: 10px;
  font-weight: 300;
  color: #7f7f7f;
  display: flex;
  align-items: center;
  border: 1px solid #7f7f7f;
  border-radius: 4px;
  padding-left: 10px;
  box-sizing: border-box;
}
@media (max-width: 680px) {
  .cardHeader {
    display: none;
  }
  .strongSection {
    display: block;
  }
  .mappedOrders {
    grid-template-columns: 1fr;
    grid-gap: 8px;
    height: 100px;
    padding-bottom: 20px;
    position: relative;
    height: max-content;
    padding: 13px 0px;
    box-sizing: border-box;
  }
  .myOrderItemCover:nth-child(4) {
    /* color: red; */
    font-weight: bold;
    right: 20px;
    bottom: 10px;
    border: none;
    height: max-content;
    position: absolute;
  }
  .myOrderItemCover:last-child {
    font-weight: bold;
    right: 13px;
    top: 15px;
    width: max-content;
    padding: 6px 9px;
    background-color: #f9f9f9;
    font-size: 10px;
    border: none;
    height: max-content;
    position: absolute;
  }
  .cardCover {
    padding: 1em 0px;
  }
  .firstForm {
    display: flex;
    margin-bottom: 20px;
  }
  .secondForm {
    display: none;
  }
  .sideEachPageHeader {
    width: max-content;
  }
  /* .view{
  font-weight: bold;
} */

  .dateCover {
    justify-content: start !important;
    margin-left: 30px;
  }
  .paginationClass {
    display: flex;
    width: max-content;
    margin-left: auto;
  }
  .paginationClass li {
    list-style: none;
    color: #521c54;
    margin: 0px 8px;
    /* padding: 8px; */
    width: 25px;
    height: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .paginationClass li[class='selected'] {
    background-color: #ff0066;
    color: #fff;
    padding-right: 1px;
  }
}
