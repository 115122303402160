.flexSection {
  width: 100%;
  display: flex;
}

.subNavCover {
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.firstForm {
  width: 100%;
  height: 40px;
  border-radius: 6px;
  /* border: 1px solid #6c757d; */
  box-shadow: 0px 0px 3px #6c757d;
  overflow: hidden;
  /* display: flex; */

  box-sizing: border-box;
  display: none;
}
.secondForm {
  width: 230px;
  margin-right: 20px;
  height: 40px;
  border-radius: 6px;
  /* border: 1px solid #6c757d; */
  box-shadow: 0px 0px 3px #6c757d;
  overflow: hidden;
  display: flex;

  box-sizing: border-box;
}
.buttonFormSection {
  border-radius: 5px;
  background-color: #ff0066;
  outline: none;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-width: 40px;
}
.searchFormSection {
  background-color: #fff;
  width: 100%;
  height: 100%;
  border: none;
  color: #6c757d;
  padding-left: 12px;
  outline: none;
}

.PageHeaderCover {
  display: flex;
  justify-content: space-between;
  height: 40px;
  align-items: center;
}
.eachPageHeader {
  /* margin-top: 8px; */
  color: #7f7f7f;
}
.sideEachPageHeader {
  display: flex;
  width: max-content;
  height: 100%;
  align-items: center;
}
.cardCover {
  width: 100%;
  position: relative;
  padding: 1em;
  box-sizing: border-box;
  padding-bottom: 2em;
  overflow: hidden;
}
.mappedStoresCover {
  width: 100%;
  overflow: hidden;
  margin-top: 20px;
}
.cardListingCover {
  width: 100%;
}
.cardHeader {
  border-color: #dee2e6;
  box-shadow: 0px 0px 5px #6c757d;
  color: #6c757d;
  filter: drop-shadow(0px 0px 2px #6c757d);
  display: grid;
  width: 100%;
  overflow: hidden;
  padding: 15px 10px;
  background: #e9eaf9 !important;
  box-sizing: border-box;
  font-size: 14px;
  align-self: center;
  text-align: center;
  grid-template-columns: 0.5fr 0.5fr 0.3fr 0.3fr 0.3fr 100px;
}
.cardHeader strong {
  place-items: center;
  margin: auto;
}
.cardHeader strong:first-child {
  margin: 0px;
  padding-left: 1em;
  box-sizing: border-box;
  margin-right: auto;
}
.strongSection {
  margin-right: 6px;
  display: none;
}
.mappedOrders {
  background-color: #fff;
  position: relative;
  height: 60px;
  margin-bottom: 2px;
  color: #6c757d;
  display: grid;
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
  font-size: 14px;
  grid-template-columns: 0.5fr 0.5fr 0.3fr 0.3fr 0.3fr 100px;
}
.mappedOrders:nth-child(odd) {
  background-color: #e9eaf998;
}
.mappedOrders div {
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  border-left: 2px solid #f5f5f5;
  height: 100%;
}
.imgSrc {
  width: 40px;
  border-radius: 5px;
  height: 40px;
  object-fit: cover;
}
.visibilityCover {
  width: max-content;
  padding: 0.4em 1.5em;
  background-color: #000033;
  border: none;
  outline: none;
  border-radius: 10px;
  color: #fff;
  font-size: 10px;
}

.actionBTNCover {
  width: 80%;
  margin: auto;
  display: flex;
  justify-content: space-between;
}
.actionBTNCover button {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  border: none;
  outline: none;
  align-items: center;
  box-sizing: border-box;
  border-radius: 50%;
  background-color: #f9f9f9;
}
.actionBTNCover svg {
  cursor: pointer;
}

.myOrderItemCover:first-child {
  place-items: left !important;
  margin-right: auto;
  padding-left: 1em;
  box-sizing: border-box;
}
.showingCover {
  width: 140px;
  height: 34px;
  font-size: 13px;
  margin-top: 10px;
  font-weight: 300;
  color: #7f7f7f;
  display: flex;
  align-items: center;
  border: 1px solid #7f7f7f;
  border-radius: 4px;
  padding-left: 10px;
  box-sizing: border-box;
}
.paginationClass {
  display: flex;
  width: max-content;
  margin-left: auto;
}
.paginationClass li {
  list-style: none;
  color: #521c54;
  margin: 0px 8px;
  cursor: pointer;
  width: 25px;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.paginationClass li[class='selected'] {
  background-color: #ff0066;
  color: #fff;
  padding-right: 1px;
}

.confirmRemoval {
  color: #000033 !important;
  margin-top: 0px;
  font-size: 16px;
}
.gridTwo {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}
.gridOne {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
  margin-top: 10px;
}
.scrollDeep {
  width: 100%;
  height: 420px;
  padding-right: 10px;
  box-sizing: border-box;
  overflow-y: scroll;
}
.flexRight {
  display: flex;
  margin-top: 20px;
  justify-content: flex-end;
}
.emptyArrayCover {
  background-color: #e9eaf9;
  width: 100%;
  height: 140px;
  display: flex;
  flex-direction: column;
  color: #7f7f7f;
  align-items: center;
  margin-top: 40px;
  justify-content: space-evenly;
}
.amountCover {
  width: max-content;
  height: max-content;
  position: relative;
  width: 100%;
}
.nairaCover {
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 50px;
  color: #6c757d;
  height: calc(1.5em + 0.9rem - 1px);
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid #ced4da;
}
.allInputCover {
  width: max-content;
  margin: 10px auto;
}
.allInputCover input {
  width: 50px;
  border-radius: 6px;
  height: 40px;
  padding-left: 20px;
  box-sizing: border-box;
  border: 1px solid #969696;
  font-size: 16px;
  color: #7f7f7f;
  margin: 0px 5px;
}

@media (max-width: 680px) {
  .cardCover {
    padding: 1em 0px;
  }
  .firstForm {
    display: flex;
    margin-bottom: 20px;
  }
  .secondForm {
    display: none;
  }
  .sideEachPageHeader {
    width: max-content;
  }
  .cardHeader {
    display: none;
  }
  .mappedOrders {
    grid-template-columns: 1fr;
    grid-gap: 8px;
    height: 100px;
    padding-bottom: 20px;
    position: relative;
    height: max-content;
    padding: 13px 0px;
    box-sizing: border-box;
  }
  .strongSection {
    padding-left: 50px;
    box-sizing: border-box;
    display: block;
  }
  .imgSrc {
    position: absolute;
    left: 20px;
    top: 13px;
  }
  .myOrderItemCover {
    place-items: left !important;
    margin-right: auto;
    padding-left: 1em;
    box-sizing: border-box;
  }
  .myOrderItemCover:nth-child(3) {
    position: absolute;
    font-weight: bold;
    right: 20px;
    bottom: 50px;
    height: max-content;
    border: none;
  }
  .myOrderItemCover:nth-child(5) {
    position: absolute;
    font-weight: bold;
    height: max-content;
    right: 20px;
    top: 15px;
    border: none;
  }
  .myOrderItemCover:nth-child(6) {
    border: none;
    width: 100%;
    margin-top: 6px;
    padding-top: 7px;
    display: flex;
    border-top: 2px solid #969696;
  }
  .flexRight {
    display: flex;
    justify-content: center;
  }
}
