.LoginFormCover {
  width: 100%;
  height: 100vh;
  /* background: linear-gradient(red, yellow); */
  /* display: none; */
  position: fixed;
  overflow: hidden;
  top: 0px;
  z-index: 1000;
  left: 0px;
  background: linear-gradient(180.04deg, #df74ad -41.5%, #b4d0e1 99.96%);
}
.relative {
  position: relative;
  display: flex;
  width: 100%;
  overflow-y: scroll;
  flex-direction: column;
  height: 100%;
}
.semiLogo {
  position: absolute;
  width: 600px;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.rightLogo {
  position: absolute;
  top: 50%;
  right: 50px;
  transform: translate(-50px, -50%);
  /* margin: auto 0px auto auto; */
}

.topRightLogo {
  width: 770px;
  position: absolute;
  right: -200px;
  transform: rotate(90deg);
  top: 30px;
}

.leftLogo {
  width: 300px;
  position: absolute;
  top: 50%;
  left: 200px;
  transform: translate(-50px, -50%);
}
.topLeftLogo {
  width: 200px;
  position: absolute;
  left: 0px;
  top: 0px;
}

body[data-side-login-mode='open'] .LoginFormCover {
  display: block;
}

.nav {
  width: 90%;
  margin: 0px auto;
  min-height: 70px;
  display: flex;
  z-index: 200;

  align-items: center;
}
.logo {
  width: 200px;
  align-self: flex-end;
}
.formCover {
  width: 500px;
  background: linear-gradient(180.04deg, #df74ad -41.5%, #b4d0e1 99.96%);
  border-radius: 10px;
  padding: 50px 0px;
  max-width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: 0.2px ease-in-out;
  margin: 30px auto;
  z-index: 1001;
}
.header {
  font-size: 30px;
  margin-top: 30px;
  color: #000033;
  font-weight: 600;
  text-align: center;
}
.main_width {
  display: flex;
  flex-direction: column;
  width: 75%;
}
.gridTwo {
  display: grid;
  /* grid-template-columns: repeat(auto-fill, minmax(100px, 1fr)); */
  grid-template-columns: 1fr 1fr;
  width: 100%;
  grid-gap: 1em;
}

.coloredLabel {
  color: #ff0066;
  font-size: 14px;
}
.inputCheckbox {
  background-color: #c3adcd !important;
}
.centeredText {
  width: 100%;
  text-align: center;
  z-index: 1001;
  margin-bottom: 60px;
}
.msgAlert {
  color: #806027;
  background-color: #fdf1db;
  position: relative;
  width: 75%;

  box-sizing: border-box;
  padding: 0.75rem 1.25rem;
  border: 1px solid #fdebcd;
  border-radius: 0.25rem;
}

.msgItself {
  color: #323a46 !important;
}
@media (max-width: 860px) {
  .leftLogo {
    width: 300px;
    left: 20px;
  }
}

@media (max-width: 660px) {
  .topLeftLogo {
    left: 100px;
  }
  .rightLogo {
    width: 200px;
    right: 0px;
    transform: translate(-50px, -50%);
  }
}

@media (max-width: 540px) {
  .semiLogo {
    margin: 0px auto;
    width: 300px;
    margin-top: auto;
    margin-bottom: 30px;
  }

  .topRightLogo {
    width: 370px;
    right: -120px;
    transform: rotate(90deg);
    top: 30px;
  }

  .rightLogo {
    width: 100px;
    right: 0px;
  }

  .header {
    font-size: 24px;
    text-align: left !important;
    margin-left: 30px;
    margin-top: 50px;
  }
  .formCover {
    margin-top: 20px;
    background: none;
    max-width: 100%;
  }
  .main_width {
    width: 85%;
  }
}
