.DashboardCardCover {
  width: 100%;
  height: 80px;
  display: flex;
  padding: 0px 1.2em;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  background-color: #e9eaf9;
}

.svgCover {
  background-color: #fff;
  width: 40px;
  height: 40px;
  border-radius: 9px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.textCoverSection {
  color: #000033;
  text-align: right;
}
