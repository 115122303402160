.mainBackgroundCover {
  position: fixed;
  width: 100%;
  height: calc(100%  + 100px);
  z-index: 100;
  background-color: #00003396;
  top: -100px;
  left: 0px;
}

.mainModal {
  width: 600px;
  border-radius: 8px;
  padding: 1.4em 1.4em 1.4em  1.4em ;
  /* height: 700px; */
  max-width: 90%;
  box-sizing: border-box;
  background-color: #fff;
  position: fixed;
  top: 50%;
  max-height: 600px;
  overflow: hidden;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}

.AiOutlineClose{
  fill: #7f7f7f;
  width: max-content;
  cursor: pointer;
  margin-left: auto;
  display: flex;
}