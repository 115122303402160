.flexSection {
  width: 100%;
  display: flex;
}
.gridDashboardCard {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
}

.firstGraph {
  position: relative;
  overflow: hidden;
  width: 100%;
  margin-top: 30px;
  background-color: #e9eaf9;
}

.firstGraph svg[style='background: rgb(255, 255, 255);'] {
  fill: #e9eaf9 !important;
  background: #e9eaf9 !important;
}

.headerSection {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  width: 100%;
  position: absolute;
  align-items: center;
  display: flex;
  padding: 1em 1.5em;
  background: #e9eaf9 !important;
  z-index: 10;
  color: #7f7f7f;
  box-sizing: border-box;
  justify-content: space-between;
  font-size: 15px;
  top: 0px;
  left: 0px;
}

.headerSection select {
  background: none;
  font-weight: bold;
  font-size: 15px;
  border: none;
  width: 60px;
}

.RecentOrderCover {
  width: 100%;
  /* background: #e9eaf9 !important; */
  margin-top: 30px;
  position: relative;
}

.gridHeaderSection {
  height: 63px;
  padding: 1em 1.5em;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  display: grid;
  grid-template-columns: 1.5fr 0.5fr 0.7fr 0.5fr 0.5fr;
  place-items: center;
  font-size: 14px;
  background: #e9eaf9 !important;
  color: #7f7f7f;
}

.gridHeaderSection strong:first-child {
  /* align-self: flex-start; */
  justify-self: flex-start;
}

.gridBodySection {
  height: 63px;
  padding: 1em 1.5em;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  display: grid;
  grid-template-columns: 1.5fr 0.5fr 0.7fr 0.5fr 0.5fr;
  font-size: 16px !important;
  color: #7f7f7f;
  background-color: #e9eaf966;
}
.gridBodySection small {
  justify-self: center;
}
.gridBodySection small:first-child {
  justify-self: flex-start;
}
.gridBodySection:nth-child(odd) {
  background-color: #fff;
}

.pending {
  width: max-content;
  padding: 0.4em 1em;
  border-radius: 12px;
  font-size: 10px;
  background-color: #ff6251;
  color: #fff;
}

.completed {
  width: max-content;
  padding: 0.4em 1em;
  border-radius: 12px;
  font-size: 10px;
  box-sizing: border-box;
  background-color: #ff0066;
  color: #fff;
}
.smallStrong {
  margin-right: 6px;
  font-size: 14px;
  display: none;
}
.centerSpan {
  justify-self: center;
}


.cardCover {
  width: 100%;
  position: relative;
  padding: 1em;
  box-sizing: border-box;
  padding-bottom: 2em;
  overflow: hidden;
}
.mappedStoresCover {
  width: 100%;
  overflow: hidden;
  margin-top: 20px;
}
.cardListingCover {
  width: 100%;
}
.cardHeader {
  border-color: #dee2e6;
  /* border-bottom: 1px solid #ddd; */
  border-bottom : 2px solid #ddd;
  color: #6c757d;
  display: grid;
  width: 100%;
  overflow: hidden;
  padding: 15px 10px;
  background: #e9eaf9 !important;
  box-sizing: border-box;
  font-size: 14px;
  align-self: center;
  text-align: center;
  grid-template-columns: 0.4fr 0.3fr 0.3fr 0.3fr 0.3fr 0.4fr 0.3fr 80px;
}
.cardHeader strong {
  place-items: center;
  margin: auto;
}
.mappedOrders {
  background-color: #fff;
  position: relative;
  height: 60px;
  margin-bottom: 2px;
  color: #6c757d;
  display: grid;
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
  font-size: 14px;
  grid-template-columns: 0.4fr 0.3fr 0.3fr 0.3fr 0.3fr 0.4fr 0.3fr 80px;
}

.mappedIncome{
  background-color: #fff;
  position: relative;
  height: 60px;
  margin-bottom: 2px;
  color: #6c757d;
  display: grid;
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
  font-size: 14px;
  grid-template-columns: 1fr 0.4fr 0.6fr 0.6fr;
}
.mappedOrders:nth-child(odd) {
  background-color: #e9eaf998;
}
.mappedOrders div {
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  border-left: 2px solid #f5f5f5;
  height: 100%;
}

.myOrderItemCover:first-child {
    box-sizing: border-box;
}
.strongSection {
  margin-right: 6px;
  display: none;
}

.trueVisibilityCover {
    width: max-content;
    padding: 0.4em 1.5em;
    background-color: #ff0066;
    border: none;
    outline: none;
    border-radius: 10px;
    color: #fff;
    font-size: 10px;
  }
.viewBTN{
    color: #ff0066;
    text-decoration: underline;
    border: none;
    cursor: pointer;
    outline: none;
    background-color: inherit;
}

.noIncome{
  width: 100%;
  height: 50px;
  background-color: #e9eaf9;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #7f7f7f;
  font-size: 15px;
}








@media (max-width: 946px) {
  .gridDashboardCard {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
}

@media (max-width: 600px) {
  .gridHeaderSection {
    display: none;
  }

  .gridBodySection {
    grid-template-columns: 1fr 0.3fr;
    height: 120px;
  }
  .gridBodySection small {
    justify-self: start;
  }
  .centerSpan {
    justify-self: start;
  }
  .smallStrong {
    display: inline;
  }
  .gridBodySection small:last-child {
    /* font-weight: bold; */
    font-size: 15px;
  }
}




@media (max-width: 780px) {
  .cardCover {
    padding: 1em 0px;
  }
  .mappedOrders {

    grid-template-columns: 1fr;
    grid-gap: 8px;
    /* height: 100px; */
    padding-bottom: 50px !important;
    position: relative;
    height: max-content;
    padding: 13px 0px;
    box-sizing: border-box;
  }
  .cardHeader {
    display: none;
  }
  .myOrderItemCover {
    place-items: left !important;
    margin-right: auto;
    padding-left: 1em;
    box-sizing: border-box;
  }
  .myOrderItemCover:nth-child(4) {
      position: absolute;
      top: 15px;
      right: 10px;
      height: max-content;
      border: none;
  }
  .myOrderItemCover:nth-child(4) .strongSection {
      font-size: 10px;
  }
  .myOrderItemCover:last-child {
      position: absolute;
      bottom: 15px;
      right: 10px;
      height: max-content;
      border: none;
  }
  .myOrderItemCover:nth-child(3) {
    position: absolute;
    bottom: 15px;
    left: 10px;
    height: max-content;
    border: none;
  }
  .myOrderItemCover:nth-child(5) {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    right: 0px;
    font-weight: bold;
    height: max-content;
    border: none;
  }
  .strongSection {
    padding-left: 5px;
    box-sizing: border-box;
    display: block;
  }
}

